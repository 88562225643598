import { graphql, Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import Container from "../components/Container";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

const BlogItem = styled.div`
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 10px 0;

  @media (min-width: 600px) {
    padding: 30px 20px;
  }

  .gatsby-image-wrapper {
    max-width: 30%;
  }

  img {
    @media (min-width: 920px) {
      max-width: 300px !important;
    }
  }
`;

const BlogLink = styled.div`
  padding: 15px 10px;
  width: 100%;

  h3 {
    color: var(--secondary);
  }

  p {
    margin-top: 20px;
  }
`;

const BlogPage = ({ data, location }) => {
  if (!data) return <p>Shooooot! No Post found!</p>;

  const posts = data.allMdx.edges;

  return (
    <Container>
      {posts.map(({ node }, index) => (
        <Link to={`/blog/${node.frontmatter.slug}`} key={index}>
          <BlogItem>
            {node.frontmatter.embeddedImage.map((image) => {
              const blogImage = getImage(image);
              return (
                <GatsbyImage
                  key={blogImage.images.fallback.src}
                  alt={node.frontmatter.imageAltText}
                  image={blogImage}
                />
              );
            })}
            <BlogLink>
              <h3>{node.frontmatter.title}</h3>
              <p>{node.excerpt}</p>
            </BlogLink>
          </BlogItem>
        </Link>
      ))}
    </Container>
  );
};

BlogPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.any,
};

export default BlogPage;

export const pageQuery = graphql`
  query blogPosts {
    allMdx(
      filter: { fields: { collection: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            slug
            description
            date(fromNow: true)
            title
            category
            imageAltText
            embeddedImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
